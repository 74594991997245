import React, { Component } from "react";
import { FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import { FaGamepad } from "react-icons/fa";

const ServiceList = [
  {
    icon: <FaGamepad />,
    title: "Game Development",
    description:
      "This is the area where I want to excel and to build a career. I have developed and worked on numerous projects. Since this is the area I'm most passionate about, it is also the area where I spend most of my time.",
  },
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "As a freelance webdeveloper I have lots of experience in designing and building a website for your needs. It is my personal goal to follow the latest trends and to build every website with these in mind.",
  },
  {
    icon: <FiMonitor />,
    title: "Application Development",
    description:
      "I've worked on many projects to create an application from scratch or to modify/expand existing ones. I prefer working on web applications because I believe the industry is going that way.",
  },
];

class ServiceThree extends Component {
  onClick = (e) => {
    e.preventDefault();
    console.log("onclick..");
  };

  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={this.onClick}>
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
