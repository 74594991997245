import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Web Application',
        title: 'Lead management and invoicing software'
    },
    {
        image: 'image-2',
        category: 'Mobile Game',
        title: 'Mobile game in the idle games genre'
    },
    {
        image: 'image-3',
        category: 'Website',
        title: 'Fully custom webdesign and development'
    },
    {
        image: 'image-4',
        category: 'Mobile Game',
        title: 'Mobile game in the puzzles genre'
    },
    {
        image: 'image-5',
        category: 'Web Application',
        title: 'Updated an old real estate website and application'
    },
    {
        image: 'image-6',
        category: 'Website',
        title: 'Custom website tailored to the needs'
    }
]

class PortfolioList extends Component{
    onClick = (e) => {
        e.preventDefault();
        console.log("onclick..");
      };

    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={this.onClick}>{value.title}</a></h4>
                                    {/* <h4><a href="/portfolio-details">{value.title}</a></h4> */}
                                    <div className="portfolio-button">
                                        {/* <a className="rn-btn" href="/portfolio-details">View Details</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;