import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Technologies",
      tab3 = "Career",
      tab4 = "Education",
      tab5 = "Personal";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab5}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            Web and Interface Design
                          </span>
                          <span> - UI/UX</span>
                          <br />
                          Delight the user and make it work.
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            Games and Applications
                          </span>
                          <span> - Programming</span>
                          <br />
                          Innovative, robust and powerfull.
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>All in One</span>
                          <span> - Full Stack</span>
                          <br />A complete solution.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <div className="row">
                        <div className="col-lg-6" style={{paddingRight:"0"}}>
                          <ul>
                            <li>
                              <span style={{ fontWeight: "500" }}>
                                Languages
                              </span>
                            </li>
                            <li>
                              <span>Java</span>
                              <span> - Advanced, 4 years</span>
                              <br />
                              <span>PHP</span>
                              <span> - Intermediate, 2 years</span>
                              <br />
                              <span>C#</span>
                              <span> - Intermediate, 3 years</span>
                              <br />
                              <span>HTML/CSS/SCSS</span>
                              <span> - Advanced, 4 years</span>
                              <br />
                              <span>JavaScript/jQuery</span>
                              <span> - Intermediate, 4 years</span>
                              <br />
                              <span>TypeScript</span>
                              <span> - Basic, 1 years</span>
                              <br />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul>
                            <li>
                              <span style={{ fontWeight: "500" }}>
                                Frameworks and Libraries
                              </span>
                            </li>
                            <li>
                              <span>Bootstrap</span>
                              <span> - Advanced, 4 years</span>
                              <br />
                              <span>CodeIgniter</span>
                              <span> - Intermediate, 2 years</span>
                              <br />
                              <span>.NET MVC</span>
                              <span> - Intermediate, 2 years</span>
                              <br />
                              <span>ME(A/R)N</span>
                              <span> - Basic, 1 years</span>
                            </li>
                            <li>
                              <span style={{ fontWeight: "500" }}>
                                Other Technologies
                              </span>
                            </li>
                            <li>
                              <span>Unity3D</span>
                              <span> - Intermediate, 3 years</span>
                              <br />
                              <span>Illustrator</span>
                              <span> - Intermediate, 2 years</span>
        
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            Jr. Full Stack PHP Developer
                          </span>
                          <span> - Creamy Coding</span>
                          <br /> 01/2019 - Current
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            Owner Indie Game Studio
                          </span>
                          <span> - Kapptan</span>
                          <br /> 01/2019 - Current
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>
                            Webdesigner
                          </span>
                          <span> - Freelance </span>
                          <br /> 09/2018 - Current
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span style={{ fontWeight: "500" }}>BSc In CS Software Developer</span>
                          <span> - University College HoGent, Ghent, Belgium</span>
                          <br /> 09/2016 - Current
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>Private Education in Philosophy and Arts</span>
                          <span> - IGMG Students, Germnany</span>
                          <br /> 06/2013 - 06/2016
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span style={{ fontWeight: "500" }}>Chairman Youth Organization</span>
                          <span> - Rede, Ghent, Belgium</span>
                          <br /> 08/2018 - Current
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>Volunteer Youth Worker</span>
                          <span> - Maanlicht, Ghent, Belgium</span>
                          <br /> 09/2016 - Current
                        </li>
                        <li>
                          <span style={{ fontWeight: "500" }}>Futsal Team Player</span>
                          <span> - Rede Futsal, Ghent, Belgium</span>
                          <br /> 09/2018 - Current
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
